import React, { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/utils";
import { PlusIcon } from "@heroicons/react/24/solid";
import { MinusIcon } from "@heroicons/react/24/solid";

export function FrequentlyAskedQuestionsAccordion({ FAQs }) {
  const [open, setOpen] = useState(null);
  return (
    (<div
      className="grid max-w-6xl grid-cols-1 gap-4 px-4 py-20 mx-auto md:grid-cols-2 md:px-8 md:py-40">
      <h2
        className="text-4xl font-bold tracking-tight text-center text-gray-900 dark:text-neutral-50 md:text-left md:text-6xl">
        Frequently asked questions
      </h2>
      <div className="divide-y divide-gray-200 dark:divide-gray-800">
        {FAQs.map((faq, index) => (
          <FAQItem
            key={index}
            question={faq.question}
            answer={faq.answer}
            open={open}
            setOpen={setOpen} />
        ))}
      </div>
    </div>)
  );
}

const FAQItem = ({
  question,
  answer,
  setOpen,
  open
}) => {
  const isOpen = open === question;

  return (
    (<div
      className="py-4 cursor-pointer"
      onClick={() => {
        if (isOpen) {
          setOpen(null);
        } else {
          setOpen(question);
        }
      }}>
      <div className="flex items-start">
        <div className="relative flex-shrink-0 w-6 h-6 mt-1 mr-4">
          <PlusIcon
            className={cn(
              "absolute inset-0 h-6 w-6 transform text-teal-500 transition-all duration-200",
              isOpen && "rotate-90 scale-0"
            )} />
          <MinusIcon
            className={cn(
              "absolute inset-0 h-6 w-6 rotate-90 scale-0 transform text-tealx-500 transition-all duration-200",
              isOpen && "rotate-0 scale-100"
            )} />
        </div>
        <div>
          <h3 className="text-lg font-medium text-neutral-700 dark:text-neutral-200">
            {question}
          </h3>
          <AnimatePresence mode="wait">
            {isOpen && (
              <motion.div
                initial={{ height: 0 }}
                animate={{ height: "auto" }}
                exit={{ height: 0 }}
                transition={{ duration: 0.2, ease: "easeOut" }}
                className="overflow-hidden text-neutral-500 dark:text-neutral-400">
                <p>{answer}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>)
  );
};