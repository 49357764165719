import {
    BanknotesIcon,
    ChatBubbleOvalLeftEllipsisIcon,
    FingerPrintIcon,
} from "@heroicons/react/20/solid";

const features = [
    {
        name: "Verified Landlords",
        description:
            "All landlords on our platform go through a biometric verification process",
        icon: FingerPrintIcon,
    },
    {
        name: "No Commissions",
        description:
            "We don't charge any commissions for searching or securing an apartment",
        icon: BanknotesIcon,
    },
    {
        name: "Active Listings",
        description:
            "Stop wasting your time on inactive or unresponsive landlords",
        icon: ChatBubbleOvalLeftEllipsisIcon,
    },
];

export default function LandingFeatures() {
    return (
        <div className="py-24 sm:py-32">
            <div className="px-6 mx-auto max-w-7xl lg:px-8">
                <div className="max-w-2xl mx-auto lg:max-w-none">
                    <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="flex flex-col">
                                <dt className="flex items-center text-base font-semibold leading-7 text-gray-900 gap-x-3">
                                    <feature.icon
                                        aria-hidden="true"
                                        className="flex-none w-5 h-5 text-teal-600"
                                    />
                                    {feature.name}
                                </dt>
                                <dd className="flex flex-col flex-auto mt-4 text-base leading-7 text-gray-600">
                                    <p className="flex-auto">
                                        {feature.description}
                                    </p>
                                </dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    );
}
