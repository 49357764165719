import { Button } from "@/Components/Catalyst/button";
import LandingBar from "@/Components/Catalyst/LandingBar";
import {
    Listbox,
    ListboxLabel,
    ListboxOption,
} from "@/Components/Catalyst/listbox";
import { FrequentlyAskedQuestionsAccordion } from "@/Components/Faq";
import { FlipWords } from "@/Components/FlipWords";
import Footer from "@/Components/Footer";
import LandingCTA from "@/Components/LandingCTA";
import LandingFeatures from "@/Components/LandingFeatures";
import { Reviews } from "@/Components/Reviews";
import { Link, Head } from "@inertiajs/react";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";

export default function Welcome({ auth, laravelVersion, phpVersion }) {
    const handleImageError = () => {
        document
            .getElementById("screenshot-container")
            ?.classList.add("!hidden");
        document.getElementById("docs-card")?.classList.add("!row-span-1");
        document
            .getElementById("docs-card-content")
            ?.classList.add("!flex-row");
        document.getElementById("background")?.classList.add("!hidden");
    };

    const flipWords = [
        "faster",
        "safer",
        "easier",
        "better",
        "smarter",
        "cooler",
        "quicker",
        "simpler",
    ];

    const reviews = [
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
        {
            title: "Naon szupi",
            body: "Pikk pakk talaltam lakast. nagyon jo a cucc.",
            author: "lepi",
            rating: 5,
        },
    ];

    const FAQs = [
        {
            question: "Bazdmeg?",
            answer: "Pontosan",
        },
        {
            question: "Hova menjek?",
            answer: "A picsaba",
        },
        {
            question: "Mi a kedvenc szined?",
            answer: "A hupikek",
        },
        {
            question: "nagyon nem tudom mit irjak ide",
            answer: "aaaaaaaa",
        },
    ];

    const [isWrapped, setIsWrapped] = useState(false);
    const flatRef = useRef(null);

    useEffect(() => {
        const handleResize = () => {
            if (flatRef.current) {
                const flatPosition = flatRef.current.getBoundingClientRect();
                const h1Position =
                    flatRef.current.parentElement.getBoundingClientRect();

                setIsWrapped(flatPosition.top > h1Position.top);
            }
        };

        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <Head title="Welcome" />
            <div className="text-black bg-gray-50 dark:bg-black dark:text-white">
                <LandingBar auth={auth} />
                <main>
                    <div className="relative isolate">
                        <svg
                            aria-hidden="true"
                            className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                        >
                            <defs>
                                <pattern
                                    x="50%"
                                    y={-1}
                                    id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                    width={200}
                                    height={200}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <path d="M.5 200V.5H200" fill="none" />
                                </pattern>
                            </defs>
                            <svg
                                x="50%"
                                y={-1}
                                className="overflow-visible fill-gray-50"
                            >
                                <path
                                    d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                    strokeWidth={0}
                                />
                            </svg>
                            <rect
                                fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)"
                                width="100%"
                                height="100%"
                                strokeWidth={0}
                            />
                        </svg>
                        <div
                            aria-hidden="true"
                            className="absolute top-0 right-0 -ml-24 overflow-hidden left-1/2 -z-10 transform-gpu blur-3xl lg:ml-24 xl:ml-48"
                        >
                            <div
                                style={{
                                    clipPath:
                                        "polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)",
                                }}
                                className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#BDC2BA] to-[#C3C9C5] opacity-30"
                            />
                        </div>
                        <div className="overflow-hidden">
                            <div className="px-6 pb-32 mx-auto max-w-7xl pt-36 sm:pt-60 lg:px-8 lg:pt-32">
                                <div className="max-w-2xl mx-auto gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                    <div className="relative w-full max-w-xl lg:shrink-0 xl:max-w-2xl">
                                        <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                            We make finding a{" "}
                                            <span ref={flatRef}>flat</span>
                                            {!isWrapped && <br />}
                                            <FlipWords words={flipWords} />
                                        </h1>
                                        <p className="mt-6 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                            Some kind of text here that makes it
                                            clear why MyPlace is the go-to for
                                            finding a flat.
                                        </p>
                                        <div className="flex items-center mt-10 gap-x-6">
                                            <Button
                                                href={route("register")}
                                                color="white"
                                                className="whitespace-nowrap"
                                            >
                                                Find your next flat in
                                                <Listbox
                                                    name="city"
                                                    defaultValue="madrid"
                                                >
                                                    <ListboxOption value="madrid">
                                                        <ListboxLabel>
                                                            Madrid
                                                        </ListboxLabel>
                                                    </ListboxOption>
                                                    <ListboxOption value="barcelona">
                                                        <ListboxLabel>
                                                            Barcelona
                                                        </ListboxLabel>
                                                    </ListboxOption>
                                                    <ListboxOption value="segovia">
                                                        <ListboxLabel>
                                                            Segovia
                                                        </ListboxLabel>
                                                    </ListboxOption>
                                                </Listbox>
                                                &rarr;
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="flex justify-end gap-8 mt-14 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                        <div className="flex-none pt-32 ml-auto space-y-8 w-44 sm:ml-0 sm:pt-80 lg:order-last lg:pt-36 xl:order-none xl:pt-80">
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="https://i.ibb.co/M5MGdtN/istockphoto-1185068383-1024x1024.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div>
                                        <div className="flex-none mr-auto space-y-8 w-44 sm:mr-0 sm:pt-52 lg:pt-36">
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="https://i.ibb.co/Pjd8Jdb/istockphoto-1351673196-1024x1024.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="https://i.ibb.co/N31Zz5J/istockphoto-1184204517-1024x1024.jpg"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div>
                                        <div className="flex-none pt-32 space-y-8 w-44 sm:pt-0">
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="https://plus.unsplash.com/premium_photo-1661887292823-f92842e8609d?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                            <div className="relative">
                                                <img
                                                    alt=""
                                                    src="https://unsplash.com/photos/OtXADkUh3-I/download?ixid=M3wxMjA3fDB8MXxhbGx8fHx8fHx8fHwxNzI1NzI0OTA5fA&force=true&w=640"
                                                    className="aspect-[2/3] w-full rounded-xl bg-gray-900/5 object-cover shadow-lg"
                                                />
                                                <div className="absolute inset-0 pointer-events-none rounded-xl ring-1 ring-inset ring-gray-900/10" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LandingFeatures />
                    <Reviews reviews={reviews} />
                    <FrequentlyAskedQuestionsAccordion FAQs={FAQs} />
                    <LandingCTA />
                    <Footer />
                </main>
            </div>
        </>
    );
}
